export const datetimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  },
  fr: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  },
  de: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  },
  nl: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  },
  pl: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  },
  es: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  },
  it: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  },
  he: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  }
} as const
